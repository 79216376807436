.our-app {
    padding-block: 120px;
    /* background: linear-gradient(180deg, rgba(255, 247, 243, 0) 0%, rgba(252, 104, 30, 0.2) 100%); */
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);

}

.content-sec {
    max-width: 886px;
    margin: auto;
    color: #4b4b4b;
}

.app-btns {
    justify-content: center;
    margin-top: 24px;
}

.app-img {
    margin-top: 50px;
    text-align: center;
}

@media (max-width:990px) {
    .app-img img {
        width: 100%;
    }

    .app-btns {
        justify-content: center;
    }

    .app-btns a {
        text-align: center;
    }
}


._1CMLi {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 330px;
    
}

.sknt2._3nMbq {
    --web-ui_button-height: auto;
    --web-ui_button-padding: 9.5px 14px;
    grid-gap: 10px;
}

._2YF0P:hover:before {
    background-color: #e8e8e8;
    transition: all 0.3s ease-in-out;
}

._1Qh5D:before {
    transition: all 0.3s ease-in-out;
    background-color: 255, 255, 255;
    border: 2px solid #e5e5e5;
    border-radius: 12px;
    bottom: -2px;
    box-shadow: 0 2px 0 #e5e5e5;
    content: "";
    background-color: #ffffff;
    left: -2px;
    position: absolute;
    right: -2px;
    top: -2px;
    z-index: -1;
}

._1F1hW {
    align-self: start;
    height: 34px;
    width: 34px;
}

._197Xn {
    display: inline-block;
}

._3nMbq ._3smle {
    font-size: 17px;
    line-height: 20px;
}

._3smle>span {
    display: grid;
}

._2YF0P {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    touch-action: manipulation;
    color: #4b4b4b;
    padding: 9.5px 14px;
    ;
    background-color: white;
    transform: translateZ(0);
}

.sknt2._3nMbq {
    --web-ui_button-height: auto;
    --web-ui_button-padding: 9.5px 14px;
    grid-gap: 10px;
}

._3smle {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 1.4;
    text-transform: none;
}

._3nMbq ._3smle>span>span {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 1px;
}

@media (min-width: 768px) {
    ._1CMLi {
        flex-direction: row;
        gap: 24px;
        justify-content: center;
        max-width: none;
        margin-top: 24px;
    }
}


@media (max-width:768px) {
    .our-app {
        padding-block: 60px;
    }
}