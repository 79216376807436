.single-benefit {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    padding-block: 120px;
}

.reverse-sec {
    flex-direction: row-reverse;
}

.reverse-sec .half-sec {
    text-align: left;
}


.low-cut.single-benefit, .reverse-sec.single-benefit {
    gap: 40px;
}

.dotted-curve {
    position: absolute;
    /* top: 359px;
    left: 206px; */
    top: 317px;
    left: 255px;
    z-index: -99;
}

.half-sec {
    width: 50%;
    color: #4b4b4b;
}

.half-sec h1 {
    font-family: Baloo Da;
    font-size: 60px;
    font-weight: 500;
    line-height: 63px;
    color: #4b4b4b;
    margin-bottom: 24px;
    text-align: left;

}

.half-sec p {
    font-family: Nunito;
    color: #777777;
    font-size: 18px;
    font-weight: 400;
    line-height: 28.8px
}

@media (max-width:1090px) {
    .single-benefit {
        flex-direction: column;
        gap: 40px;
        padding-block: 60px;
    }

    .dotted-curve {
        display: none;
    }

    .half-sec {
        width: 100%;

    }

    .ben-img {
        text-align: center;
    }

    .half-sec p {
        text-align: left;
    }
}

@media (max-width: 768px) {

    .ben-img img {
        width: 80%;
    }



    .single-benefit:not(:last-child) {
        padding-bottom: 0 !important;
    }

}