
.coming-soon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.title {
    font-family: 'Baloo Da';
    font-size: 4rem;
    color: #fc681e;
}

.subtitle {
    font-family: sans-serif;
    font-size: 1.0rem;
    color: #8a8a8a;
    margin-top: 0.5rem;
}