.top-pd {
    padding-top: 86px;
}

.hero-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 84px;
    background-color: #fff;
    /* min-height: 100vh; */
    box-sizing: border-box;
}

.hero-graphic {
    position: absolute;
    right: 0;
    text-align: right;
    top: 86px;
}

.hero-content {
    max-width: 50%;
    padding-block: 120px;

}


.hero-content h1 {
    font-family: Baloo Da, sans-serif;
    font-size: 65px;
    font-weight: 500;
    color: #4b4b4b;
    margin-bottom: 24px;
    line-height: 1.1;
}

.hero-content p {
    font-family: Nunito;
    font-size: 18px;
    color: #4B5563;
    margin-bottom: 24px;
}

.hero-buttons {
    display: flex;
    gap: 15px;

}



.hero-graphic img {
    max-width: 100%;
    height: auto;
}

/* Responsive Design */
@media (max-width:1200px) {
    .hero-graphic img {
        max-width: 70%;
        height: auto;
    }

    .hero-content {
        padding-block: 80px;
    }
}

@media (max-width:980px) {
    .hero-graphic img {
        max-width: 60%;
        height: auto;
    }

    .hero-content {
        padding-block: 25px;
    }
}

@media (max-width: 768px) {
    .hero-container {
        padding-bottom: 60px;
    }

    .hero-buttons {
        justify-content: center;
    }

    .hero-container {
        flex-direction: column;
        align-items: center;
        text-align: center;

    }

    .hero-content {
        max-width: 100%;
        margin-bottom: 0px;
        padding-bottom: 0;
    }

    .hero-graphic {
        top: 470px;
        display: none;
    }

    .hero-graphic img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }

}

@media (max-width:612px) {
    .hero-graphic {
        top: 530px;
    }

}

@media (max-width:500px) {
    .hero-graphic {
        top: 620px;
    }



    .hero-buttons {
        flex-direction: column;
    }
}

@media (max-width:435px) {
    .hero-graphic {
        top: 670px;
    }

}

@media (max-width:335px) {
    .hero-graphic {
        top: 720px;
    }

}

._1CMLi_hero {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 330px;
}

.sknt2._3nMbq {
    --web-ui_button-height: auto;
    --web-ui_button-padding: 9.5px 14px;
    grid-gap: 10px;
}

._2YF0P:hover:before {
    background-color: #fd8b52;
    transition: all 0.3s ease-in-out;
}

._1Qh5D:before {
    transition: all 0.3s ease-in-out;
    background-color: 255, 255, 255;
    border: 2px solid #e5e5e5;
    border-radius: 12px;
    bottom: -2px;
    box-shadow: 0 2px 0;
    color: 229, 229, 229;
    content: "";
    background-color: #fc681e;
    left: -2px;
    position: absolute;
    right: -2px;
    top: -2px;
    z-index: -1;
}

._1F1hW {
    align-self: start;
    height: 34px;
    width: 34px;
}

._197Xn {
    display: inline-block;
}

._3nMbq ._3smle {
    font-size: 17px;
    line-height: 20px;
}

._3smle>span {
    display: grid;
}

._2YF0P {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    touch-action: manipulation;
    color: #303030;
    padding: 9.5px 14px;
    ;
    /* border: 2px solid; */
    background-color: white;
    transform: translateZ(0);
}

.sknt2._3nMbq {
    --web-ui_button-height: auto;
    --web-ui_button-padding: 9.5px 14px;
    grid-gap: 10px;
}

._3smle {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 1.4;
    text-transform: none;
}

._3nMbq ._3smle>span>span {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 1px;
}

@media (min-width: 768px) {
    ._1CMLi_hero {
        flex-direction: row;
        gap: 24px;
        justify-content: start;
        max-width: none;
    }
}