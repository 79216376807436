.contact {
    padding-block: 120px;
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);

    color: white;
}

.info {
    min-width: 411px;
    max-width: 411px;
}

.contact h1 {
    font-family: Baloo Da;
    color: black;
    font-size: 60px;
    font-weight: 500;
    line-height: 63px;
    text-align: left;
    margin-bottom: 10px;

}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);

    border-radius: 50%;
    border-top: 4px solid #3b3b3b;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.alert-danger {
    color: red;
}

.contact-p {
    color: #3b3b3b;
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    text-align: left;
    padding-bottom: 30px;
    border-bottom: 1px solid #525252
}

.contact-info {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid #525252
}

.flex-info {
    display: flex;
    align-items: center;
    gap: 16px;
}

.flex-info p {

    font-family: DM Sans;
    font-size: 20.99px;
    font-weight: 500;
    line-height: 28.83px;
    text-align: left;


}

.social-icons {
    padding-top: 30px;
    display: flex;
    align-items: center;
    gap: 21px;
}


.flex-contact {
    display: flex;
    align-items: stretch;
    gap: 211px;
    color: black;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    width: 100%;
    color: #ffffff;
}

.contact-form textarea {
    height: 200px;
    resize: none;
    border-radius: 21px !important;
}

.contact-form input,
.contact-form textarea {
    padding: 23px 50px 23px 50px;
    box-sizing: border-box;
    border: 1px solid gray; /* Replaces outline with border */
    width: 100%;
    border-radius: 51px; /* This will now curve the border */
    color: gray;
    font-family: "Urbanist", sans-serif !important;
    font-weight: 400;
    line-height: 21.6px;
    font-size: 18px;
    text-align: left;
    background: #ffffff; /* Ensure background color is set if needed */
}

.swap-btn:disabled {

    cursor: default !important;
    opacity: 0.5;
}

.swap-btn:hover {
    transform: scale(1.02);
}

.swap-btn {
    transition: all 0.3s ease-in-out;
    padding: 24px 56px 24px 56px;

    border-radius: 40px;
    color: white;
    ;
    font-family: DM Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 19.5px;

    display: flex;
    justify-content: center;
    align-items: center;

    border: 2px solid #FC681E;
    cursor: pointer;
    outline: none;
    background: #FC681E;


}

.flexo {
    margin-left: 22px;
    color: red;
    display:
        flex;
    gap: 5px;
    align-items: center;
    margin-top: 5px;
}

.error {
    color: red;
    line-height: normal;
    /* margin: 0; */
}

.alert-success i {

    font-size: 30px;
}

.alert-success {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    font-weight: bold;
}

@media (max-width:1200px) {
    .flex-contact {
        gap: 60px;
    }
}

@media (max-width:768px) {
    .flex-contact {
        flex-direction: column;
    }

    .info {
        min-width: auto;
        max-width: none;

    }

    .contact {
        padding-block: 60px;
    }
}