.about {
    padding-block: 80px 120px;
}

.about-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.mb-3 {
    margin-bottom: 30px;
}

.sec-area p {
    color: #303030;
}

.sec-area .heading-line {
    font-family: Fredoka;
    font-size: 60px;
    font-weight: 500;
    text-align: left;

}

.sec-area h3 {
    margin: 0;
    text-align: left;
    line-height: 100px;
}

.big-font {
    font-family: "Baloo Da";
    font-size: 120px;
    color: #FC681E;
}

@media (max-width:990px) {
    .sec-area img {
        width: 250px;
    }
}

@media (max-width:768px) {
    .sec-area img {
        width: 350px;
    }

    .about-inner {
        flex-direction: column;
    }

    .about {
        padding-block: 60px;
    }
}

@media (max-width:450px) {
    .sec-area img {
        width: 280px;
    }

    .about-inner {
        flex-direction: column;
    }
}