.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 86px;
  background-color: white;
  position: relative;
  /*  */


}

.main-navbar {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: all 0.3s ease;
}

.navbar-links li a:hover {
  color: #FC681E;
}


.term-page .term-privacy-link {
  display: flex !important;
}

.term-page .navbar-contact, .term-page .home-link {
  display: none !important;
}

.privacy-page .term-privacy-link {
  display: flex !important;
}

.privacy-page .navbar-contact, .privacy-page .home-link {
  display: none !important;
}

.fixed-navbar {
  position: fixed;
  top: 0;
  background-color: white;
  left: 0;
  animation: slideInFromTop 0.3s ease forwards;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 9999;
}


.fixed-navbar.slide-in {
  transition: all 0.3s ease;
  transform: translateY(-100%);
}

.fixed-navbar.slide-in.show {
  transition: all 0.3s ease;
  transform: translateY(0);
}


.navbar-logo .logo-text {
  font-size: 24px;
  font-weight: bold;
  color: #FC681E;
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;

  padding: 0;
}

.navbar-links ul {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  gap: 24px;
  list-style: none;
}

.navbar-links li a {
  font-size: 16px;
  font-weight: 400;
  color: #000;

  cursor: pointer;
}

.navbar-contact {
  margin-left: 24px;
}

.navbar-contact .contact-button {
  padding: 13px 36px 13px 36px;
  border: 1px solid #FC681E;
  background-color: white;
  color: #FC681E;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.navbar-contact .contact-button:hover {
  background-color: #FC681E;
  color: white;
}

/* Mobile Styles */
.mobile-menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.mobile-menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
  transition: all 0.3s ease;
}

.mobile-menu-icon.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.mobile-menu-icon.open .bar:nth-child(2) {
  opacity: 0;
}

.mobile-menu-icon.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Responsive */
@media (max-width: 768px) {
  .navbar-links {
    position: absolute;
    top: 86px;
    left: 0;
    width: 100%;
    background-color: white;
    /* display: none; */
    flex-direction: column;
    align-items: center;
    display: flex;
    /*  */
    overflow: hidden;
    height: 0;
    transition: all 0.3s ease;
  }

  .navbar-links.open {

    border-bottom: 1px solid #ddd;
    overflow: hidden;
    height: 430px;
    transition: all 0.3s ease;
  }

  .navbar-links ul {
    flex-direction: column;
    width: 100%;
  }

  .navbar-links li {
    margin: 15px 0;
  }

  .navbar-contact {
    display: none;
  }

  .mobile-menu-icon {
    display: flex;
  }
}

@keyframes slideInFromTop {
  from {
    top: -200px;
  }

  to {
    top: 0;
  }
}