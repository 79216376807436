.footer {
  padding-block: 80px 50px;
}

.upper-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 34px;
  border-bottom: 1px solid rgba(156, 163, 175, 1);
}

.lower-too {
  border-bottom: none;
  padding-top: 34px;
  padding-bottom: 0 !important;
}

.upper-sec img {
  width: 156px;
}

.footer-links {
  display: flex;
  gap: 22px;
  margin: 0;
  list-style: none
}

.footer-links li a {

  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;

  color: var(--gray-600, rgba(75, 85, 99, 1));

}

.mont li a {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
}

.all-right {
  color: var(--gray-600, rgba(75, 85, 99, 1));
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;


}

.footer-links a:hover,
.footer-links .mont a:hover {
    color: #FC681E;
}

@media (max-width:768px) {
  .upper-sec {
    flex-direction: column;
    gap: 30px
  }
}

@media (max-width:500px) {
  .footer-links {
    flex-direction: column;
    padding: 0;
    text-align: center;
  }
}