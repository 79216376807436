* {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    font-family: "DM Sans", sans-serif;
    font-optical-sizing: auto;

    font-style: normal;
}


.sec-width {
    max-width: 1300px;
    margin: auto;

    padding-inline: 20px;
}

/* 
.max-wid2 {
    display: flex; 
    justify-content: flex-end;
} */

/* .testimonial-slider {
    max-width: 1300px;
    margin: auto;

} */

.max-wid2 {
    margin: 0 auto;
    position: relative;
}


.testimonial-slider {
    margin-left: calc((100vw - 1300px) / 2);
}

a {
    text-decoration: none;

}

.relative {
    position: relative;
}

p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

.theme-color {
    color: #FC681E;
}

.heading-line {
    font-family: Baloo Da;
    font-size: 60px;
    font-weight: 500;
    line-height: 63px;
    text-align: center;

    margin-bottom: 24px;
}

.heading-section {
    font-family: Baloo Da;
    font-size: 50px;
    font-weight: 500;
    line-height: 63px;
    text-align: left;
    /*color: gray; */
    margin-bottom: 24px;
}

.heading-inner {
    font-family: Baloo Da;
    font-size: 40px;
    font-weight: 500;
    /* text-align: center; */
    margin-block: 15px;
}

.heading-para {
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 400;
    color: gray;
    line-height: 28.8px;
    text-align: center;


}



.text-left {
    text-align: left;
}

@media (max-width: 768px) {

    .heading-inner {
        font-family: Fredoka;
        font-size: 35px;
        font-weight: 500;

    }

    .hero-content h1, .half-sec h1, .heading-line {
        font-size: 48px;
        line-height: normal;
    }

    .hero-buttons img {
        width: 200px;
    }

}

@media (max-width:500px) {
    .heading-inner {
        font-family: Fredoka;
        font-size: 30px;
        font-weight: 500;

    }

    .hero-content h1, .half-sec h1, .heading-line {
        font-size: 35px;
    }

    .hero-content p, .heading-para,
    .half-sec p {
        font-size: 16px;
    }
}