.faq {
    padding-block: 120px;
    background: #F4F4F4;

}

.mb-0 {
    margin-bottom: 0 !important;
}

.accordion {
    max-width: 776px;
    margin: 0 auto;
    margin-top: 40px;
}

.accordion-item {
    padding-block: 15px;
    border-bottom: 1px solid #ddd;
}

.accordion-header {
    font-family: DM Sans;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    color: #27262B;

    text-align: left;

}


.accordion-header {
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    padding-inline: 0;
    background-color: transparent;
    outline: none;
    font-size: 16px;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .accordion-header:hover {
    background-color: #e2e2e2;
} */

.accordion-content {
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.accordion-content p {

    color: #27262B;
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    text-align: left;

    margin: 0;
}

@media (max-width:768px) {
    .faq {
        padding-block:
            60px;
    }
}