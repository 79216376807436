.reviews {
    padding-block: 120px 80px;
}

.flex-space {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;

}

.flex-space h1 {
    max-width: 600px;
    margin-bottom: 0;
    text-align: left;

}

.flex-space button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
}

.single-slide .heading-para {
    text-align: left;
    margin-block: 30px;
    color: #27262B;
    ;
}

.single-slide {
    background: #FFF7F3;
    width: 450px;
    padding: 40px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;

}

.name {
    font-family: DM Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #27262B;


}

.country-name {
    font-family: DM Sans;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    text-align: left;
    color: #27262B;

}

.flex-pic {
    display: flex;
    align-items: center;
    gap: 14px;
}

.stars {
    display: flex;
}

.slick-slide>div {
    margin-right: 20px;
}

.slider-btns button:disabled {
    /* opacity: 0.4; */
    cursor: default !important;
}

@media (max-width:1000px) {
    .slick-slide>div {
        margin-inline: 10px;
    }

    .testimonial-slider {
        margin: 0;
    }
}

@media (max-width:500px) {
    .flex-space {
        margin-bottom: 30px;
        gap: 30px;
        flex-direction: column;
    }
}