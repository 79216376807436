.mt-3s {
    margin-top: 30px;
}

.text-left p {
    text-align: left;
    margin-bottom: 10px;
}

.term-ul {

    padding-left: 1.2rem;

}

.term-ul li {
    font-family: DM Sans;
    margin-block: 5px;
    font-size: 18px;
    font-weight: 400;
    color: gray;
}