.main-bar {
    border-width: 1px 0px 1px 0px;

    border-style: solid;

    border-color: #D1D5DB;


}

.categories-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    padding-block: 60px;
    background-color: #fff;
}

.category-item {
    text-align: center;


}

.category-item img {
    transition: transform 0.3s ease;  /* Smooth transition for transform changes */
    display: block;  /* Ensures the image is properly aligned and transitions smoothly */
    margin: 0 auto;
    margin-bottom: 4px;
}

.category-item:hover img {
    transform: scale(1.1);
}




.category-item p {
    font-family: Nunito;
    font-size: 20px;
    margin: 0;
    color: #303030;
}

/* Responsive Design */
@media (max-width: 768px) {
    .categories-container {
        flex-wrap: wrap;
        column-gap: 0;
        row-gap: 50px;
    }

    .category-item {
        margin-bottom: 20px;
        width: 50%;
    }
}

@media (max-width:420px) {

    .category-item {
        margin-bottom: 20px;
        width: 100%;
    }
}